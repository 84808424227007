@import '../../variable.scss';
.menu{

    background-color: $light-green;
    &__wrapper{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
    }

    &__list, &__user{
        list-style-type: none;
        display: flex;
        margin: 0;
        padding: 0;
    }

    &__item + &__item{
        margin-left: 20px;
    }

    &__link{
        text-decoration: none;
        color: $black;
        &_block{
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &:hover{
            color: $green;
        }
    }

    &__open{
        display: none;
    }

    label{
        display: none;
    }
}
@media screen and (max-width: 676px){
    .menu{

        &__wrapper{
            flex-wrap: wrap;
        }

        &__list, &__user{
            display: none;
            width: 100%;
            margin-top: 20px;
            justify-content: center; 
        }

        label{
            display: block;
            svg{
                width: 35px;
                height: auto;
            }
        }

        &__open{
            &:checked ~ .menu__list, &:checked ~ .menu__user{
                display: flex;
            }
        }
    }
}