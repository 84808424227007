@import '../../variable.scss';

.cart{
    background-color: $green;
    padding: 100px 0;

    &__wrapper{
        display: flex;
        justify-content: space-between;
    }

    &__photos{
        display: flex;
        justify-content: space-between;
        width: 55%;
    }
    &__vertical-photos{
        width: 120px;
        .swiper{
            height: 700px;
    
            &-slide{
                width: 120px;
            }
        }
        .cart__img{
            width: 100%;
            height: auto;
            border-radius: 10px;
        }
    }

    &__main-photo{
        width: calc(100% - 120px);
        margin-left: 30px;


        .cart__img{
            border-radius: 10px;
            width: 100%;
            height: auto;
        }


        .swiper{
            &-button{ 
                &-prev, &-next{
                    width: 54px;
                    height: 54px;
                    background: $green;
                    position: absolute;
                    border-radius: 100%;
                    &::after{
                        content: url('data:image/svg+xml; utf8, <svg width="16" height="30" viewBox="0 0 16 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.84961 28.1998L12.7163 17.3331C13.9996 16.0498 13.9996 13.9498 12.7163 12.6665L1.84961 1.7998" stroke="white" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/></svg>');
                        position: relative;
                    }
                }
    
                &-prev{
                    &::after{
                        transform: rotate(180deg);
                        left: -2px;
                        top: 2px;
                    }
                }
    
                &-next{
                    &::after{
                        right: -2px;
                    }
                }
            }
        }
    }

    &__data{
        width: 45%;
        margin-left: 100px;
        margin-top: 100px;
    }

    &__brand, &__text{
        margin-top: 10px;
        @include simpleText();
    }

    &__prices{
        margin-top: 10px;
        display: flex;
        align-items: center;
    }

    &__new-price{
        margin: 0;
        font-weight: 500;
        span{
            color: #A51111;
        }
    }

    &__old-price{
        color: #4C4E4A;
        @include simpleText();
        margin: 0;
        margin-left: 20px;
        text-decoration: line-through #4C4E4A 2px;
    }
    
    &__sizes{
        margin-top: 10px;
        display: flex;
        align-items: flex-start;
    }

    &__radio{
        display: none;

        &:checked + label{
            background-color: $light-green;
        }
    }

    &__size{
        @include simpleText(); 
        padding: 6px 12px;
        border: 1px solid $gray;
        border-radius: 10px;
        margin-right: 10px;
        cursor: pointer;
        width: 50px;
        display: block;
    }

    &__basket{
        margin-top: 30px;
        @include button();
        width: 100%;
    }
   
}

.description{
    padding: 100px 0;

    &__text{
        @include simpleText();
        margin-top: 50px;  
    }
}

.detail{
    padding: 0 0 100px;

    &__wrapper{
        margin-top: 50px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        row-gap: 20px;
    }

    &__item{
        width: 46%;
        display: flex;
        justify-content: space-between;
        padding: 10px;
        border-bottom: 1px dashed $gray;
        
        p{
            @include  simpleText();
        }
    }
}

.review{
    padding: 100px 0;
    background-color: $gray;
    color: $white;

    &__wrapper{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__new-open{
        @include button();
        font-size: 16px;
        width: 20%;
        padding: 10px 0;
    }

    &__item{
        padding: 10px;
        border-bottom: 1px solid $white;
        margin-top: 50px;
    }

    &__user-name{
        @include text(24px, 400, 36px);
        margin: 0;
    }

    &__stars{
        margin-top: 10px;
        svg{
            margin-right: 5px;
        }
    }
    
    &__text{
        @include simpleText();
        margin-top: 10px;
    }

    &__date{
        @include text(18px, 400, 36px);
        margin-top: 25px;
    }

    &__more{
        @include button();
        margin: 50px auto 0 auto;
        width: 30%;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }

    &__new{
        position: fixed;
        top: 50%;
        left: 50%;
        width: 30%;
        transform: translate(-50%, -50%);
        background-color: $green;
        padding: 50px 25px;
        border-radius: 10px;
        text-align: center;
        z-index: 100;
    }

    &__content{
        resize: none;
        border: 1px solid $black;
        border-radius: 10px;
        background-color: transparent;
        padding: 10px;
        outline: none;
        width: 100% !important;
        margin-top: 30px;

        &::placeholder{
            color: $black
        }
    }

    &__save{
        @include button();
        margin-top: 30px;
    }

    &__close{
        cursor: pointer;
        background-color: transparent;
        border: none;
        outline: none;
        padding: 0;

        position: absolute;
        top: 10px;
        right: 10px;
        svg{
            width: 20px;
            height: 20px;
            path{
                fill: lighten($black, 50%);
            }
        }

        &:hover{
            svg{
                path{
                    fill: $black;
                }
            }
        }
    }
    &__error{
        text-align: center;
        margin-bottom: 10px;
    }
}
.recommend{
    padding: 100px 0;
}

.rating{
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    &__radio{
        display: none;
    }

    &__star{
        margin-right: 5px;
        cursor: pointer;

        svg{
            width: 25px;
            height: auto;
        }
    }

    & > input:checked ~ label svg path {
        fill: #FFFD54;    
    }

    &:not(:checked) > label:hover svg path,
    &:not(:checked) > label:hover ~ label svg path{
        fill: #b9b722;  
    }

    & > input:checked > label:hover ~ label svg path,
    & > input:checked + label:hover ~ label svg path,
    & > input:checked ~ label:hover ~ label svg path,
    & > label:hover ~ input:checked ~ label svg path{
        fill: #b9b722;
}

}


@media screen and (max-width: 1200px){
    .cart{

        &__wrapper{
            align-items: center;
        }
        &__photos{
            display: flex;
            justify-content: space-between;
            width: 60%;
        }

        &__vertical-photos{
            width: 100px;
            .swiper{
                height: 700px;
                &-slide{
                    width: 100px;
                }
            }
        }

        &__sizes{
            flex-wrap: wrap;
            gap: 10px 10px;
        }

        &__data{
            margin-left: 30px;
            margin-top: 0;
        }
        
    }

    .review{
        &__new{
            width: 40%;
        }
    }
}

@media screen and (max-width: 991px){
    .cart{
        &__vertical-photos{
            .swiper{
                height: 500px;
            }
        }
    }
    .review{
        &__new{
            width: 55%;
        }

        &__new-open{
            width: 40%;
        }
    }
}
@media screen and (max-width: 767px){
    .cart{
        &__wrapper{
            display: block;
        }
        &__photos{
            width: 80%;
        }
        &__data{
            width: 100%;
            margin: 20px 0 0 0;
        }
    }

    .detail{
        &__wrapper{
            display: block;
        }

        &__item{
            width: 75%;
        }
    }

}

@media screen and (max-width: 650px){
    .cart{
        &__photos{
            width: 100%;
        }

        .swiper{
            &-button{ 
                &-prev, &-next{
                    width: 40px;
                    height: 40px;
                }
            }
        }
    }
    .detail{
        &__item{
            width: 100%;
        }
    }
    .review{
        &__new{
            width: 75%;
        }
    }

}

@media screen and (max-width: 500px){
    .cart{
        &__vertical-photos{
            .swiper{
                height: 300px;
            }
        }
    }
}

@media screen and (max-width: 450px){
    .cart{
        &__vertical-photos{
            display: none;
        }

        &__main-photo{
            width: 100%;
            margin-left: 0;
        }
    }

    .review{
        &__new-open{
            font-size: 14px;
            width: 50%;
        }

        &__user-name{
            font-size: 20px;
        }

        &__text{
            font-size: 16px;
        }

        &__date{
            font-size: 14px;
            margin-top: 0;
        }
    }
}