@import '../../variable.scss';

.account{
    

    &__edit-product{
        min-height: 60vh;

        .product__img{
            width: 100% !important;
        }
    }
    &__search{
        width: 50%;
        position: relative;
        text-align: center;
        margin: 50px auto;

        &-input{
            width: 100%;
            padding: 10px 40px 10px 10px;
            border: none;
            outline: none;
            border-radius: 10px;
        }

        &-btn{
            position: absolute;
            background-color: transparent;
            border: none;
            outline: none;
            cursor: pointer;
            background-image: url('data:image/svg+xml;charset=US-ASCII, <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.4999 11H11.7099L11.4299 10.73C12.6299 9.33001 13.2499 7.42002 12.9099 5.39002C12.4399 2.61002 10.1199 0.390015 7.31989 0.0500152C3.08989 -0.469985 -0.470107 3.09001 0.0498932 7.32001C0.389893 10.12 2.60989 12.44 5.38989 12.91C7.41989 13.25 9.32989 12.63 10.7299 11.43L10.9999 11.71V12.5L15.2499 16.75C15.6599 17.16 16.3299 17.16 16.7399 16.75C17.1499 16.34 17.1499 15.67 16.7399 15.26L12.4999 11ZM6.49989 11C4.00989 11 1.99989 8.99002 1.99989 6.50002C1.99989 4.01002 4.00989 2.00002 6.49989 2.00002C8.98989 2.00002 10.9999 4.01002 10.9999 6.50002C10.9999 8.99002 8.98989 11 6.49989 11Z" fill="black"/></svg>');
            background-repeat: no-repeat;
            background-position: center;
            z-index: 5;
            width: 24px;
            height: 24px;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
            font-size: 18px;
        }
    }

    &__not-search{
        @include text(32px, 400, 40px);
        color: $white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-18%, -50%);
        text-align: center;
    }

    &__search-product{
        display: flex;
        justify-content: center;
        gap: 50px;
        flex-wrap: wrap;

        .product{
            width: 27%;
            &_data {
                 margin-left: 0; 
            }
        }

        .product__img{
            width: 100%;
        }
    }
}

@media screen and (max-width: 1000px){
    .account{
        &__search-product{
            .product{
                width: 35%;
            }
        }
    }
}

@media screen and (max-width: 850px){
    .account{
        &__search-product{
            gap: 15px;
            .product{
                width: 47%;
            }
        }
    }
}

@media screen and (max-width: 768px){
    .account{
        &__search{
            width: 80%;
        }
    }
}

@media screen and (max-width: 678px){
    .account{
        &__search{
            margin: 0 auto 30px;
        }

        &__search-product{
            .product{
                width: 60%;
            }
        }
    }
}

@media screen and (max-width: 500px){
    .account{
        &__not-search{
            transform: translate(-50%, -20%);
        }

        &__search-product{
            .product{
                width: 60%;
            }
        }

        &__menu{
            &-admin{
                flex-wrap: wrap;
                .account__link{
                    width: 50%;
                    text-align: center;
                }
            }
        }
    }
}
@media screen and (max-width: 380px){
    .account{
        &__search-product{
            .product{
                width: 80%;
            }
        }
    }
}