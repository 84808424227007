@import '../../variable.scss';

.product{
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    text-align: center;
    text-decoration: none;
    color: #000;
    position: relative;

    &:hover .heart{
        display: block;
    }

    &__img{
        width: 100%;
        height: auto;
        border-radius: 10px;
    }

    &__data{
        margin: 0 !important;
        text-decoration: none;
        color: #000;
        display: block;
        margin-bottom: 40px !important;
    }

    .heart{
        display: none;
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
    }

    &__name{
        &_simple{
            @include simpleText();
            margin-top: 15px;
        }
    }
    &__brand{
        @include simpleText();
        margin-top: 3px;
    }

    &__prices{
        display: flex;
        align-items: center;
        position: absolute;
        width: 100%;
        bottom: 10px;
    }

    &__new-price{
        @include simpleText();
        margin-top: 10px;
        font-weight: 500;

        span{
            color: #A51111;
            margin-right: 3px;
        }
    }

    &__old-price{
        color: #4C4E4A;
        @include text(18px, 500, 27px);
        margin: 0;
        margin-top: 10px;
        margin-left: 20px;
        text-decoration: line-through #4C4E4A 2px;
    }
}