@import '../../variable.scss';

.basket{
    padding: 100px 0;
    background-color: $green;

    &__wrapper{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 50px;
    }

    &__products{
        width: 60%;
    }

    &__product{
        display: flex;
        align-items: center;
        padding-bottom: 30px;
        border-bottom: 1px solid $white;
        position: relative;
        
        & + &{
            margin-top: 50px;
        }
    }

    &__sum{
        width: 30%;
        padding: 20px;
        background-color: $light-green;
        border-radius: 10px;
    }

    &__cost, &__payable{
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include simpleText();
        margin-bottom: 15px;
        p{
            margin: 0;
        }
    } 

    &__payable p:first-child{
        @include text(24px, 700, 40px);
    }

    &__pay{
        @include button();
        width: 100%;
        margin-top: 30px;
    }

    &__delete{
        cursor: pointer;
        background-color: transparent;
        border: none;
        outline: none;
        padding: 0;

        position: absolute;
        top: 0;
        right: 0;
        svg{
            width: 20px;
            height: 20px;
            path{
                fill: lighten($black, 50%);
            }
        }

        &:hover{
            svg{
                path{
                    fill: $black;
                }
            }
        }
    }

    &__empty{
        height: 50vh;
        position: relative;
        div{
            position: absolute;
            top: 30%;
            left: 0;
            text-align: center; 
            width: 100% 
        }
        
        
        &-text{
            @include text(32px, 500, 35px);
            color: $white;
           
        }
    }

    &__shop{
        @include button();
        width: 20%;
    }

}

@media screen and (max-width: 1000px){
    .basket{
        &__sum{
            width: 35%;
        }
    }
}

@media screen and (max-width: 900px){
    .basket{
        &__shop{
            width: 40%;
        }
    }
}

@media screen and (max-width: 850px){
    .basket{
        .basket__wrapper{
            display: block;
        }
        &__products{
            width: 100%;
        }
        &__sum{
            margin: 40px auto 0;
            width: 50%;
        }
    }
}
@media screen and (max-width: 768px){
    .basket{
        padding: 50px 0;

        &__empty-text{
            font-size: 26px;
        }

        &__shop{
            font-size: 18px;
        }
    }
}

@media screen and (max-width: 600px){
    .basket{
        padding: 50px 0;
        
        &__sum{
            width: 75%;
        }
    }
}

@media screen and (max-width: 500px){
    .basket{
        &__shop{
            width: 80%;
        }
    }
}

@media screen and (max-width: 400px){
    .basket{
        &__sum{
            width: 100%;
        }
    }
}





